.replace(/\[\s*[\r\n]+/g, "[")<!-- MixedJsonViewer.vue -->
<template>
  <div class="mixed-content-viewer">
    <div v-for="(segment, index) in parsedSegments" :key="index" class="content-segment">
      <JsonViewer v-if="segment.type === 'json'" :data="segment.content" />
      <pre
        v-else
        class="text-content"
      ><code>{{ formatText(segment.content) }}</code></pre>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-undef */
import { computed } from "vue";
import JsonViewer from "./JsonViewer.vue";

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
});

// 添加文本格式化函数
const formatText = (text) => {
  if (typeof text !== "string") return text;
  // 移除 [ 前的换行符
  return text
    .replace(/<br>/g, "\n")
    .replace(/<br\/>/g, "\n")
    .replace(/<br \/>/g, "\n");
};

const parsedSegments = computed(() => {
  const segments = [];
  let remainingContent = props.content;

  while (remainingContent.length > 0) {
    const jsonStart = findJsonStart(remainingContent);

    if (jsonStart === -1) {
      if (remainingContent) {
        segments.push({ type: "text", content: remainingContent });
      }
      break;
    }

    if (jsonStart > 0) {
      segments.push({
        type: "text",
        content: remainingContent.slice(0, jsonStart),
      });
    }

    const { parsed, length } = tryParseJson(remainingContent.slice(jsonStart));

    if (parsed !== null) {
      segments.push({ type: "json", content: parsed });
      remainingContent = remainingContent.slice(jsonStart + length);
    } else {
      segments.push({
        type: "text",
        content: remainingContent.slice(jsonStart, jsonStart + 1),
      });
      remainingContent = remainingContent.slice(jsonStart + 1);
    }
  }

  return segments;
});

/**
 * 查找可能的JSON开始位置
 * @param {string} text - 要搜索的文本
 * @returns {number} - JSON可能的开始位置，如果没找到返回-1
 */
function findJsonStart(text) {
  const jsonStartChars = ["{", "["];
  const indexes = jsonStartChars
    .map((char) => text.indexOf(char))
    .filter((i) => i !== -1);
  return indexes.length > 0 ? Math.min(...indexes) : -1;
}

/**
 * 尝试解析JSON
 * @param {string} text - 要解析的文本
 * @returns {{ parsed: any, length: number }} - 解析结果和长度
 */
function tryParseJson(text) {
  let depth = 0;
  let inString = false;
  let escape = false;
  let result = "";

  // 检查是否以有效的JSON开始字符开头
  if (text[0] !== "{" && text[0] !== "[") {
    return { parsed: null, length: 0 };
  }

  // 逐字符分析
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    result += char;

    // 处理转义字符
    if (escape) {
      escape = false;
      continue;
    }

    if (char === "\\" && inString) {
      escape = true;
      continue;
    }

    // 处理字符串
    if (char === '"' && !escape) {
      inString = !inString;
      continue;
    }

    if (inString) continue;

    // 处理对象和数组的嵌套
    if (char === "{" || char === "[") {
      depth++;
    } else if (char === "}" || char === "]") {
      depth--;
      // 找到完整的JSON结构
      if (depth === 0) {
        try {
          // 移除多余的换行和空格
          const cleanJson = result.replace(/\n\s*\[/g, "[");
          const parsed = JSON.parse(cleanJson);
          return { parsed, length: i + 1 };
        } catch {
          return { parsed: null, length: 0 };
        }
      }
    }
  }

  return { parsed: null, length: 0 };
}
</script>

<style scoped>
.mixed-content-viewer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

.content-segment {
  margin: 0;
}

.text-content {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  word-break: break-word;
  color: #374151;
  transition: color 0.2s ease;
  font-size: 16px;
  line-height: 1.6;
}

pre {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

code {
  font-family: inherit;
  padding: 0;
  background: none;
}

/* 暗色主题支持 */
:root.dark .text-content {
  color: #e5e7eb;
}

/* 特殊文本样式 */
.text-content ::selection {
  background-color: rgba(66, 184, 131, 0.2);
  color: inherit;
}

/* 添加一些视觉层次 */
.content-segment + .content-segment {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

/* 优化可读性 */
.text-content {
  letter-spacing: 0.01em;
}

/* 暗色模式下的选中效果 */
:root.dark .text-content ::selection {
  background-color: rgba(66, 184, 131, 0.3);
}
</style>
