<template>
  <div id="app" :class="{ 'dark': isDarkMode }">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="updateKey" />
    </router-view>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, provide } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {
    const isDarkMode = ref(false)
    const updateKey = ref(0)

    const updateDarkMode = (dark) => {
      isDarkMode.value = dark
      document.documentElement.classList.toggle('dark', dark)
      document.body.style.backgroundColor = dark ? '#1a1a1a' : '#ffffff'
      localStorage.setItem('darkMode', dark.toString())
      updateKey.value++ // Force child components to re-render
    }

    const toggleDarkMode = () => {
      updateDarkMode(!isDarkMode.value)
    }

    watch(() => localStorage.getItem('darkMode'), (newValue) => {
      if (newValue !== null) {
        updateDarkMode(newValue === 'true')
      }
    })

    onMounted(() => {
      const savedMode = localStorage.getItem('darkMode')
      if (savedMode !== null) {
        updateDarkMode(savedMode === 'true')
      } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
        updateDarkMode(prefersDark)
      }
    })

    // Provide dark mode state and toggle function to all child components
    provide('darkMode', isDarkMode)
    provide('toggleDarkMode', toggleDarkMode)

    return {
      isDarkMode,
      updateKey
    }
  }
})
</script>

<style>
:root {
  --bg-color: #ffffff;
  --text-color: #2c3e50;
}

:root.dark {
  --bg-color: #1a1a1a;
  --text-color: #c9d1d9;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>