import './assets/styles.css'
import './assets/main.css'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import TranslateText from './components/TranslateText.vue'
import locales from './locales'

// 创建 i18n 实例
const i18n = createI18n({
  legacy: false, // 启用 Composition API 模式
  locale: 'zh', // 设置默认语言
  fallbackLocale: 'en', // 设置回退语言
  messages: locales,
})

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:lang?',
      component: TranslateText,
      beforeEnter: (to, from, next) => {
        const lang = to.params.lang
        const supportedLanguages = ['zh', 'en', 'ja']
        if (!lang) {
          next('/zh') // 默认重定向到中文
        } else if(!supportedLanguages.includes(lang)) {
          next('/zh') // 如果语言不支持，重定向到中文
        } else {
          next()
        }
      }
    }
  ]
})

// 创建 Vue 应用实例
const app = createApp(App)

// 使用插件
app.use(i18n)
app.use(router)

// 挂载应用
app.mount('#app')