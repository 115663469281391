export default {
    zh: {
      appName: 'EasyRead Tools',
      tagline: '格式化、美化和查看器一体化工具',
      description1: '处理和查看各种数据格式，让复杂的数据展现变得简单易读',
      description2: '对包括字符串、Unicode、URL编码、时间戳在内的多种数据进行解码',
      description3: '解析JSON字符串进行格式化展示，带参数高亮，支持不完整的JSON',
      inputPlaceholder: "",
      defaultOutput: "",
      translationError: '翻译时发生错误'
    },
    en: {
      appName: 'EasyRead Tools',
      tagline: 'Formatter Beautifier and Viewer All in One',
      description1: 'Process and view various data formats, making complex data presentation simple and readable',
      description2: 'Decode multiple types of data including strings, Unicode, URL encoding, and timestamps',
      description3: 'Parse JSON strings for formatted display, with parameter highlighting, supporting incomplete JSON',
      inputPlaceholder: "",
      defaultOutput: "",
      translationError: 'Error occurred during translation'
    },
    ja: {
      appName: 'EasyRead Tools',
      tagline: 'フォーマッター、ビューティファイヤー、ビューワーオールインワン',
      description1: '様々なデータ形式を処理・表示し、複雑なデータ表現を簡単で読みやすくします',
      description2: '文字列、Unicode、URLエンコーディング、タイムスタンプなど、多種多様なデータをデコードします',
      description3: 'JSON文字列を解析してフォーマットされた表示を行い、パラメータをハイライト表示し、不完全なJSONもサポートします',
      inputPlaceholder: "",
      defaultOutput: "",
      translationError: '翻訳中にエラーが発生しました'
    }
  };