<template>
  <div class="json-node">
    <div class="node-content" :style="{ marginLeft: isNested ? '20px' : '0' }">
      <template v-if="isObject">
        <div class="object-wrapper">
          <span class="toggle" @click="toggleExpanded">
            {{ expanded ? "▼" : "▶" }}
          </span>
          <span class="bracket">{{ isArray ? "[" : "{" }}</span>
          <span v-if="!expanded" class="preview">...</span>
          <span v-if="!expanded" class="bracket">{{ isArray ? "]" : "}" }}</span>
        </div>
        <div v-if="expanded" class="object-content">
          <div v-for="(value, key) in props.data" :key="key" class="property">
            <div class="property-line">
              <span class="key">"{{ key }}":</span>
              <JsonNode :data="value" :is-nested="true" class="inline-value" />
            </div>
          </div>
          <span class="bracket end-bracket">{{ isArray ? "]" : "}" }}</span>
        </div>
      </template>
      <span v-else-if="typeof props.data === 'string'" class="string"
        >"{{ props.data }}"</span
      >
      <span v-else-if="typeof props.data === 'number'" class="number">{{
        props.data
      }}</span>
      <span v-else-if="typeof props.data === 'boolean'" class="boolean">{{
        props.data
      }}</span>
      <span v-else-if="props.data === null" class="null">null</span>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-undef */
import { ref, computed } from "vue";

const props = defineProps({
  data: {
    type: [Object, Array, String, Number, Boolean],
    required: true,
  },
  isNested: {
    type: Boolean,
    default: false,
  },
});

const expanded = ref(true);

const isObject = computed(() => {
  return typeof props.data === "object" && props.data !== null;
});

const isArray = computed(() => {
  return Array.isArray(props.data);
});

const toggleExpanded = () => {
  expanded.value = !expanded.value;
};
</script>

<style scoped>
.json-node {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}

.node-content {
  line-height: 1.6;
}

.object-wrapper {
  display: inline-flex;
  align-items: center;
}

.toggle {
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
  display: inline-block;
  width: 12px;
  text-align: center;
  color: #666;
}

.property {
  margin-left: 20px;
}

.property-line {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

/* 浅色主题颜色 */
.key {
  color: #7c3aed; /* 紫色 */
}

.string {
  color: #0891b2; /* 青色 */
}

.number {
  color: #0d9488; /* 蓝绿色 */
}

.boolean {
  color: #2563eb; /* 蓝色 */
}

.null {
  color: #6b7280; /* 灰色 */
}

.bracket {
  color: #374151; /* 深灰色 */
}

.preview {
  color: #6b7280;
  margin: 0 5px;
}

.inline-value {
  display: inline-flex;
  flex: 1;
}

.end-bracket {
  display: block;
  margin-left: 0;
}

/* 暗色主题颜色 */
:root.dark .key {
  color: #c084fc; /* 亮紫色 */
}

:root.dark .string {
  color: #67e8f9; /* 亮青色 */
}

:root.dark .number {
  color: #5eead4; /* 亮蓝绿色 */
}

:root.dark .boolean {
  color: #93c5fd; /* 亮蓝色 */
}

:root.dark .null {
  color: #9ca3af; /* 亮灰色 */
}

:root.dark .bracket {
  color: #e5e7eb; /* 亮白色 */
}

:root.dark .toggle {
  color: #9ca3af;
}

:root.dark .preview {
  color: #9ca3af;
}

/* 悬停效果 */
.toggle:hover {
  color: #42b883; /* Vue 绿色 */
}

:root.dark .toggle:hover {
  color: #42b883;
}

/* 添加过渡效果 */
.key,
.string,
.number,
.boolean,
.null,
.bracket,
.toggle {
  transition: color 0.2s ease;
}
</style>
