<template>
  <div
    class="translate-text-container bg-white dark:bg-[#032029] text-[#213547] dark:text-[#c9d1d9] transition-colors duration-300 flex-grow flex flex-col"
  >
    <!-- 导航栏 -->
    <nav
      class="fixed top-0 left-0 right-0 z-50 bg-white dark:bg-[#032029] border-b border-[#e5e7eb] dark:border-[#0a3a4a] transition-colors duration-300"
    >
      <div class="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 w-full md:w-[95%] lg:w-[92%]">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <h1 class="text-xl font-bold text-[#213547] dark:text-white">
                {{ t("appName") }}
              </h1>
            </div>
          </div>
          <div class="flex items-center">
            <!-- 夜间模式切换 -->
            <button
              @click="toggleDarkMode"
              class="p-2 rounded-md text-[#213547] hover:text-[#42b883] dark:text-[#c9d1d9] dark:hover:text-[#42b883] focus:outline-none"
            >
              <svg
                v-if="darkMode"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                />
              </svg>
            </button>
            <!-- 语言切换 -->
            <div class="ml-4 relative">
              <button
                @click="toggleLanguageMenu"
                class="flex items-center text-sm font-medium text-[#213547] hover:text-[#42b883] dark:text-[#c9d1d9] dark:hover:text-[#42b883] focus:outline-none"
                id="language-menu"
                aria-expanded="false"
                aria-haspopup="true"
              >
                {{ currentLanguage }}
                <svg
                  class="ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                v-if="showLanguageMenu"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-[#043540] ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="language-menu"
              >
                <a
                  v-for="lang in languages"
                  :key="lang.code"
                  href="#"
                  @click.prevent="changeLanguage(lang.code)"
                  class="block px-4 py-2 text-sm text-[#213547] dark:text-[#c9d1d9] hover:bg-gray-100 dark:hover:bg-[#054757]"
                  role="menuitem"
                  >{{ lang.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- 主要内容 -->
    <div class="pt-20 pb-10 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col items-center mx-auto">
        <div
          class="content flex flex-col md:flex-row justify-center items-start bg-white dark:bg-[#032029] p-5 shadow-md rounded-lg w-full md:w-11/12 lg:w-10/12"
        >
          <textarea
            v-model="inputText"
            @input="autoResize"
            :placeholder="t('inputPlaceholder')"
            class="input-text flex-1 min-h-[15rem] md:mr-2 border border-[#e5e7eb] dark:border-[#0a3a4a] rounded p-3 w-full bg-white dark:bg-[#032029] text-[#213547] dark:text-[#c9d1d9] focus:outline-none focus:border-[#42b883] dark:focus:border-[#42b883] transition-colors duration-300 text-base leading-relaxed"
            ref="inputTextarea"
            rows="1"
          >
          </textarea>
          <div
            class="output-text flex-1 min-h-[15rem] md:ml-2 border border-[#e5e7eb] dark:border-[#0a3a4a] bg-[#f9f9f9] dark:bg-[#043540] rounded p-3 overflow-auto w-full text-[#213547] dark:text-[#c9d1d9] text-base leading-relaxed"
          >
            <div
              v-if="!processedOutputText"
              class="text-[#6e7681] dark:text-[#8b949e] text-base"
            >
              暂无内容
            </div>
            <MixedJsonViewer
              v-else-if="processedOutputText"
              :content="processedOutputText[0]"
            />
          </div>
        </div>
      </div>
      <h2
        id="app-ad-heading"
        class="mb-0 pt-12 text-center text-5xl font-normal text-[#213547] dark:text-white"
        style="margin-top: 2rem"
      >
        {{ t("appName") }}
      </h2>
      <div class="flex flex-col items-center justify-center mt-8">
        <span class="text-xl font-normal text-[#213547] dark:text-[#c9d1d9] mb-4">{{
          t("tagline")
        }}</span>
        <p class="text-base font-normal text-[#4b5563] dark:text-[#a3b1c2] mb-2">
          {{ t("description1") }}
        </p>
        <p class="text-base font-normal text-[#4b5563] dark:text-[#a3b1c2] mb-2">
          {{ t("description2") }}
        </p>
        <p class="text-base font-normal text-[#4b5563] dark:text-[#a3b1c2] mb-2">
          {{ t("description3") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, inject, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import MixedJsonViewer from "./MixedJsonViewer.vue";

export default {
  name: "TranslateText",
  components: {
    MixedJsonViewer,
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const inputText = ref("");
    const outputText = ref([]);
    const showLanguageMenu = ref(false);
    const languages = ref([
      { code: "zh", name: "简体中文" },
      { code: "en", name: "English" },
      { code: "ja", name: "日本語" },
    ]);

    const inputTextarea = ref(null);
    const debug = ref(true);

    // Inject dark mode state and toggle function from App.vue
    const darkMode = inject("darkMode");
    const toggleDarkMode = inject("toggleDarkMode");

    const currentLanguage = computed(() => {
      return languages.value.find((lang) => lang.code === locale.value).name;
    });

    const adjustInputHeight = () => {
      if (inputTextarea.value) {
        inputTextarea.value.style.height = "auto";
        inputTextarea.value.style.height = `${inputTextarea.value.scrollHeight}px`;
      }
    };

    const processedOutputText = computed(() => {
      if (!outputText.value || outputText.value.length === 0) {
        return null;
      }

      return outputText.value
        .map((item) => {
          if (!item || !item.content) return null;

          if (typeof item.content === "object") {
            return item.content;
          }

          if (typeof item.content === "string") {
            return item.content;
          }
          return item.content;
        })
        .filter(Boolean);
    });

    // 解码字节序列
    const decodeByteSequence = (str) => {
      return str.replace(/(\\x[0-9a-fA-F]{2})+/g, (match) => {
        try {
          const bytes = match.split("\\x").filter((x) => x);
          const buf = new Uint8Array(bytes.map((x) => parseInt(x, 16)));
          return new TextDecoder().decode(buf);
        } catch (e) {
          return match;
        }
      });
    };

    // 解码 Unicode 序列
    const decodeUnicodeSequence = (data) => {
      // 处理 \uXXXX 格式
      data = data.replace(/\\u([0-9a-fA-F]{4})/g, (_, p1) =>
        String.fromCharCode(parseInt(p1, 16))
      );

      // 处理 u 前缀
      const uPrefixPattern = /\bu(['"])((?:[^'"\\]|\\.)*)\1/g;
      while (uPrefixPattern.test(data)) {
        data = data.replace(uPrefixPattern, (_, quote, content) => {
          return quote + decodeUnicodeSequence(content) + quote;
        });
      }

      return data;
    };

    // 解码 URL
    const decodeUrlsInString = (inputString) => {
      const decodedString = decodeURIComponent(inputString);
      const parts = decodedString.split(/\s+/);

      return parts
        .map((part) => {
          if (part.includes("http://") || part.includes("https://")) {
            return decodeURIComponent(part);
          }
          return part;
        })
        .join(" ");
    };

    // 转换时间戳
    const convertTimestampsInJsonString = (jsonString) => {
      const now = new Date();
      const fifteenDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);
      const fifteenDaysLater = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
      const timestampLowerBound = Math.floor(fifteenDaysAgo.getTime() / 1000);
      const timestampUpperBound = Math.floor(fifteenDaysLater.getTime() / 1000);

      return jsonString.replace(/\b\d+\b/g, (match) => {
        const value = parseInt(match);
        if (value >= timestampLowerBound && value <= timestampUpperBound) {
          return `"${new Date(value * 1000).toLocaleString()}"`;
        }
        return match;
      });
    };

    // 主解码函数
    const parseAndDecode = (rawData) => {
      // 替换换行符
      let data = rawData.replace(/\n/g, "<br>");

      // 解码字节序列
      data = decodeByteSequence(data);

      // 解码 Unicode
      data = decodeUnicodeSequence(data);

      // 解码 URL
      data = decodeUrlsInString(data);

      // 转换时间戳
      data = convertTimestampsInJsonString(data);

      // 再次解码 Unicode（与 Python 版本保持一致）
      data = decodeUnicodeSequence(data);

      // 尝试解析 JSON
      try {
        const jsonContent = JSON.parse(data);
        return [
          {
            type: "json",
            content: jsonContent,
          },
        ];
      } catch (e) {
        return [
          {
            type: "text",
            content: data,
          },
        ];
      }
    };

    // 修改原有的 translateText 函数
    const translateText = async () => {
      if (!inputText.value) {
        outputText.value = [];
        localStorage.removeItem("translatorOutput");
        return;
      }

      try {
        const decoded = parseAndDecode(inputText.value);
        outputText.value = decoded;
      } catch (error) {
        console.error("Error translating:", error);
        outputText.value = [{ type: "text", content: t("translationError") }];
      }
    };

    const handleInput = () => {
      adjustInputHeight();
      translateText();
    };

    const toggleLanguageMenu = () => {
      showLanguageMenu.value = !showLanguageMenu.value;
    };

    const changeLanguage = (lang) => {
      router.push(`/${lang}`);
      locale.value = lang;
      showLanguageMenu.value = false;
    };

    watch(
      () => route.params.lang,
      (newLang) => {
        if (newLang && locale.value !== newLang) {
          locale.value = newLang;
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      // Check system color scheme
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      const handleChange = (e) => {
        if (localStorage.getItem("darkMode") === null) {
          toggleDarkMode(e.matches);
        }
      };
      mediaQuery.addEventListener("change", handleChange);
      handleChange(mediaQuery); // Initial check

      // Ensure initial language matches URL
      const urlLang = route.params.lang;
      if (urlLang && locale.value !== urlLang) {
        locale.value = urlLang;
      }

      // Initial height adjustment
      adjustInputHeight();

      // 从 localStorage 恢复状态
      const savedInput = localStorage.getItem("translatorInput");
      const savedOutput = localStorage.getItem("translatorOutput");
      if (savedInput) {
        inputText.value = savedInput;
      }
      if (savedOutput) {
        try {
          outputText.value = JSON.parse(savedOutput);
        } catch (e) {
          console.error("Error parsing saved output:", e);
        }
      }
    });

    // 保存状态到 localStorage
    watch(
      [inputText, outputText],
      ([newInput, newOutput]) => {
        localStorage.setItem("translatorInput", newInput);
        localStorage.setItem("translatorOutput", JSON.stringify(newOutput));
      },
      { deep: true }
    );

    // 自动调整文本框高度的函数
    const autoResize = () => {
      const textarea = inputTextarea.value;
      if (!textarea) return;

      // 重置高度
      textarea.style.height = "auto";

      // 设置新高度
      textarea.style.height = `${textarea.scrollHeight}px`;

      // 触发翻译
      handleInput();
    };

    // 在组件挂载后初始化高度
    onMounted(() => {
      // 恢复保存的状态
      const savedInput = localStorage.getItem("translatorInput");
      const savedOutput = localStorage.getItem("translatorOutput");
      if (savedInput) {
        inputText.value = savedInput;
        // 在下一个 tick 调整高度，确保内容已经渲染
        nextTick(() => {
          autoResize();
        });
      }
      if (savedOutput) {
        try {
          outputText.value = JSON.parse(savedOutput);
        } catch (e) {
          console.error("Error parsing saved output:", e);
        }
      }
    });

    return {
      inputText,
      darkMode,
      showLanguageMenu,
      languages,
      currentLanguage,
      handleInput,
      toggleDarkMode,
      toggleLanguageMenu,
      changeLanguage,
      t,
      inputTextarea,
      processedOutputText,
      debug,
      autoResize, // 导出自动调整高度的方法
      translateText,
    };
  },
};
</script>

<style>
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .input-text,
  .output-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    min-height: 15rem;
  }
}

.translate-text-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
}

#app {
  min-height: 100vh;
}

.input-text,
.output-text {
  overflow-y: auto;
  height: auto;
  min-height: 15rem;
  font-size: 16px;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
}

.input-text {
  resize: none;
  overflow-y: hidden; /* 隐藏滚动条 */
  min-height: 15rem;
  transition: height 0.1s ease;
}

.output-text {
  min-height: 15rem;
  height: auto;
}

.mixed-content-viewer {
  color: inherit;
}

.input-text::placeholder {
  color: #6e7681;
}

.dark .input-text::placeholder {
  color: #8b949e;
}

.input-text:focus {
  outline: none;
  border-color: #42b883;
}

.dark .input-text:focus {
  border-color: #42b883;
}

/* 确保文本颜色在暗色模式下有足够对比度 */
.dark .input-text,
.dark .output-text {
  color: #c9d1d9;
  background-color: #043540;
}

.dark .mixed-content-viewer {
  color: #c9d1d9;
}
</style>
